// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#viewport-tester {
  display: none;
  content: " ";
  width: 1px;
  height: 1px;
}

@media (min-width: 1281px) {
  #viewport-tester.desktop {
    display: block;
  }
}

@media (min-width: 1024px) {
  #viewport-tester.laptop {
    display: block;
  }
}

@media (min-width: 768px) {
  #viewport-tester.tablet {
    display: block;
  }
}

@media (min-width: 481px) {
  #viewport-tester.phablet {
    display: block;
  }
}

@media (min-width: 320px) {
  #viewport-tester.mobile {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/style/view-port.scss","webpack://./src/style/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;AADF;;ACHI;EDOJ;IAEI,cAAA;EADF;AACF;;ACJI;EDQJ;IAEI,cAAA;EADF;AACF;;ACLI;EDSJ;IAEI,cAAA;EADF;AACF;;ACNI;EDUJ;IAEI,cAAA;EADF;AACF;;ACPI;EDWJ;IAEI,cAAA;EADF;AACF","sourcesContent":["@import \"mixins.scss\";\r\n\r\n#viewport-tester {\r\n  display: none;\r\n  content: ' ';\r\n  width: 1px;\r\n  height: 1px;\r\n}\r\n\r\n#viewport-tester.desktop {\r\n  @include breakpoint(desktop) {\r\n    display: block;\r\n  }\r\n}\r\n\r\n#viewport-tester.laptop {\r\n  @include breakpoint(laptop) {\r\n    display: block;\r\n  }\r\n}\r\n\r\n#viewport-tester.tablet {\r\n  @include breakpoint(tablet) {\r\n    display: block;\r\n  }\r\n}\r\n\r\n#viewport-tester.phablet {\r\n  @include breakpoint(phablet) {\r\n    display: block;\r\n  }\r\n}\r\n\r\n#viewport-tester.mobile {\r\n  @include breakpoint(mobile) {\r\n    display: block;\r\n  }\r\n}\r\n","﻿@mixin breakpoint($point) {\r\n  @if $point == desktop {\r\n    @media (min-width: 1281px) {\r\n      @content;\r\n    }\r\n  }\r\n  @else if $point == laptop {\r\n    @media (min-width: 1024px) {\r\n      @content;\r\n    }\r\n  }\r\n  @else if $point == tablet {\r\n    @media (min-width: 768px) {\r\n      @content;\r\n    }\r\n  }\r\n  @else if $point == phablet {\r\n    @media (min-width: 481px) {\r\n      @content;\r\n    }\r\n  }\r\n  @else if $point == mobile {\r\n    @media (min-width: 320px) {\r\n      @content;\r\n    }\r\n  }\r\n}\r\n\r\n@mixin transition($value) {\r\n  transition: $value;\r\n  -webkit-transition: $value;\r\n  -moz-transition: $value;\r\n  -ms-transition: $value;\r\n  -o-transition: $value;\r\n}\r\n\r\n@mixin transform($value) {\r\n  -webkit-transform: $value;\r\n  -ms-transform: $value;\r\n  transform: $value;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
